import axios from "axios"

export default async function getStoryblokCacheVersion(): Promise<number> {
  try {
    const res = await axios.get(
      `https://api.storyblok.com/v1/cdn/spaces/me?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_KEY}`
    )
    return res.data?.space?.version ?? Date.now()
  } catch (_) {
    return Date.now().valueOf()
  }
}
