import { useEffect, useState } from "react"
import axios from "axios"

import Storyblok from "@src/utilities/storyblok"

import populateCards from "@src/utilities/populate-cards"

type StoryblokEnterEditmodeEvent = {
  storyId: string
}
type StoryblokInputEvent = {
  story: onyx.Types.Storyblok
}
export default function useStoryblokBridge(
  originalStory: onyx.Types.Storyblok,
  apiKey: string = "",
  preview: boolean
) {
  if (apiKey === "" || !preview) {
    return originalStory
  }

  const [story, setStory] = useState<any>(originalStory)

  function initEventListeners() {
    const { StoryblokBridge }: any = window
    if (typeof StoryblokBridge !== "undefined") {
      // initialize the bridge with your token
      const storyblokInstance = new StoryblokBridge()

      // reload on Next.js page on save or publish event in the Visual Editor
      storyblokInstance.on(["change", "published"], () =>
        window.location.reload()
      )

      // live update the story on input events
      storyblokInstance.on("input", (event: StoryblokInputEvent) => {
        if (story && event.story.content._uid === story.content._uid) {
          populateCards(event.story, apiKey).then((storyWithCards) => {
            setStory(storyWithCards)
          })
        }
      })

      storyblokInstance.on(
        "enterEditmode",
        (event: StoryblokEnterEditmodeEvent) => {
          // loading the draft version on initial enter of editor
          Storyblok(apiKey)
            .get(`cdn/stories/${event.storyId}`, {
              version: "draft",
            })
            .then(({ data }) => data.story ?? null)
            .then((storyblokStory) => populateCards(storyblokStory, apiKey))
            .then((storyblokStory) => {
              setStory(storyblokStory)
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                console.log(error.response?.data)
              } else {
                console.log(error)
              }
            })
        }
      )
    }
  }

  function addBridge(callback: () => void) {
    const existingScript = document.getElementById("storyblokBridge")
    if (existingScript) {
      callback()
      return
    }
    const script = document.createElement("script")
    script.src = "//app.storyblok.com/f/storyblok-v2-latest.js"
    script.id = "storyblokBridge"
    document.body.appendChild(script)
    script.onload = () => {
      callback()
    }
  }

  useEffect(() => {
    if (preview) {
      addBridge(initEventListeners)
    }
  }, [])

  return story
}
