import _find from "lodash/find"
import _has from "lodash/has"
import _map from "lodash/map"

import apolloClient from "@src/utilities/apollo-client"
import {
  GET_BUNDLE_PRICES,
  GET_FULL_PRODUCT_LIBRARY_FROM_API,
} from "@src/operations/api/product"
import {
  GET_BUNDLE_CARD_ITEM,
  GET_PRODUCT_CARD_ITEM,
} from "@src/utilities/graphql-storyblok-queries"

import getStoryblokCacheVersion from "@src/workers/storyblok/get-cache-version"

import type { BundleProductCard } from "@src/entities/models/bundle-product-card"

import type {
  GetBundlePricesQuery,
  GetBundlePricesQueryVariables,
  GetFullProductLibraryFromApiQuery,
} from "@src/entities/types/api"

import type {
  GetBundleCardItemQuery,
  GetBundleCardItemQueryVariables,
  GetProductCardItemQuery,
  GetProductCardItemQueryVariables,
} from "@src/entities/types/storyblok"

async function getBundleForCard(
  card: BundleProductCard,
  cacheVersion: number,
  apiKey?: string
): Promise<BundleProductCard> {
  if (!card.link_to_bundle_page?.cached_url) {
    return card
  }

  return apolloClient
    .query<GetBundleCardItemQuery, GetBundleCardItemQueryVariables>({
      context: {
        clientName: "storyblok",
        headers: {
          Token: apiKey ?? process.env.STORYBLOK_API_KEY_PREVIEW,
          Version: cacheVersion,
        },
      },
      query: GET_BUNDLE_CARD_ITEM,
      variables: { slug: card.link_to_bundle_page.cached_url },
    })
    .then(({ data }) => {
      if (
        !data.BundlepageItem?.content?.bundled_products_sanity?.skus ||
        data.BundlepageItem?.content?.bundled_products_sanity.skus.length === 0
      ) {
        return card
      }
      return apolloClient
        .query<GetBundlePricesQuery, GetBundlePricesQueryVariables>({
          query: GET_BUNDLE_PRICES,
          variables: {
            skus: data.BundlepageItem.content.bundled_products_sanity.skus,
          },
        })
        .then((response) => {
          if (!response?.data) {
            return card
          }
          return {
            ...card,
            bundle: {
              name: data.BundlepageItem?.content?.product_name ?? "",
              description:
                data.BundlepageItem?.content?.product_short_description ?? null,
              fullSlug: data.BundlepageItem?.full_slug ?? "",
              priceOnSale: response.data.bundle.priceOnSale ?? null,
              priceRetail: response.data.bundle.priceRetail,
              thumbnailUrl:
                data.BundlepageItem?.content?.thumbnail?.filename ?? null,
              thumbnailUrlHover:
                data.BundlepageItem?.content?.thumbnail_hover?.filename ?? null,
            },
          }
        })
    })
}

async function getProductForCard(
  card: BundleProductCard,
  cacheVersion: number,
  productLibrary: GetFullProductLibraryFromApiQuery["productLibrary"],
  apiKey?: string
): Promise<BundleProductCard> {
  if (!card.link_to_product_page?.cached_url) {
    return card
  }
  return apolloClient
    .query<GetProductCardItemQuery, GetProductCardItemQueryVariables>({
      context: {
        clientName: "storyblok",
        headers: {
          Token: apiKey ?? process.env.STORYBLOK_API_KEY_PREVIEW,
          Version: cacheVersion,
        },
      },
      query: GET_PRODUCT_CARD_ITEM,
      variables: { slug: card.link_to_product_page.cached_url },
    })
    .then(({ data, errors }) => {
      if (errors || !data.ProductpageItem?.content?.sanity_product?.sku) {
        return card
      }
      const product = _find(
        productLibrary,
        (p) => p.sku === data.ProductpageItem?.content?.sanity_product.sku
      )
      if (!product) {
        return card
      }
      return {
        ...card,
        product: {
          amazonUrl: data?.ProductpageItem?.content?.amazon_link?.url || null,
          name: data.ProductpageItem?.content?.product_name ?? "",
          description:
            data.ProductpageItem?.content?.product_short_description ?? null,
          fullSlug: data.ProductpageItem?.full_slug ?? "",
          priceOnSale: product.priceOnSale ?? null,
          priceRetail: product.priceRetail,
          priceSubscription: product.priceSubscription ?? null,
          sku: data.ProductpageItem?.content?.sanity_product.sku,
          thumbnailUrl:
            data.ProductpageItem?.content?.thumbnail?.filename ?? null,
          thumbnailUrlHover:
            data.ProductpageItem?.content?.thumbnail_hover?.filename ?? null,
        },
      }
    })
}

export default async function populateProductCards(
  story: any,
  storyblokApiKey?: string
): Promise<any> {
  const { content } = story

  if (!_has(content, "body") && !_has(content, "page_builder")) {
    return {
      ...story,
    }
  }

  const cacheVersion = await getStoryblokCacheVersion()

  const productLibrary = await apolloClient
    .query<GetFullProductLibraryFromApiQuery>({
      query: GET_FULL_PRODUCT_LIBRARY_FROM_API,
    })
    .then(({ data }) => data.productLibrary)
  const body = await Promise.all(
    _map(content.body ?? [], (block) => {
      if (
        block?.component !== "product_grid" ||
        !block?.product_cards ||
        block.product_cards.length === 0
      ) {
        return Promise.resolve(block)
      }
      return Promise.all(
        _map(block.product_cards, (card) => {
          if (card?.component === "bundle_card") {
            return getBundleForCard(card, cacheVersion, storyblokApiKey)
          }
          return getProductForCard(
            card,
            cacheVersion,
            productLibrary,
            storyblokApiKey
          )
        })
      ).then((result) => ({
        ...block,
        product_cards: result,
      }))
    })
  )

  const pageBuilder = await Promise.all(
    _map(content.page_builder ?? [], (block) => {
      if (
        block?.component !== "product_grid" ||
        !block?.product_cards ||
        block.product_cards.length === 0
      ) {
        return block
      }
      return Promise.all(
        _map(block.product_cards, (card) => {
          if (card?.component === "bundle_card") {
            return getBundleForCard(card, cacheVersion, storyblokApiKey)
          }
          return getProductForCard(
            card,
            cacheVersion,
            productLibrary,
            storyblokApiKey
          )
        })
      ).then((result) => ({
        ...block,
        product_cards: result,
      }))
    })
  )

  return {
    ...story,
    content: {
      ...story.content,
      ...(body.length > 0 && { body }),
      ...(pageBuilder.length > 0 && { page_builder: pageBuilder }),
    },
  }
}
